import React from "react";

const WebApp = () => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <div className="head">
          Vous avez un besoin de développement spécifique ou souhaitez tester
          rapidement votre idée auprès de votre public cible&nbsp;?
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <p>
          Nous ne faisons pas uniquement des sites vitrines et e-commerce, nous
          pouvons également vous accompagner dans le développement de prototypes
          (MVP) ou d'application complexe impliquant l'utilisation d'API.
        </p>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <p>
          Quelques exemples d'applications web&nbsp;: plateforme de réservation,
          tableau de bord, chatbot, scripts AdWords, etc.
        </p>
      </div>
    </div>
  );
};

export default WebApp;
