import React from "react";

const Process = () => {
  return (
    <section id="process1" className="process process-1">
      <div className="container">
        <div className="row heading heading-2 mb-50">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Comment ça marche ?</div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2>Nos étapes</h2>
          </div>
          {/* <div className="col-sm-12 col-md-12 col-lg-6">
            <p>
              We intend to form a solid relationships with each customer we work
              with, to know them as individuals, how they think and feel.
            </p>
          </div> */}
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-panel">
              <div className="process-content">
                <div className="step">01.</div>
                <h3>Contact</h3>
                <p>
                  Nous échangeons pour bien comprendre votre besoin et vous
                  proposer la solution qui vous correspond.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-panel">
              <div className="process-content">
                <div className="step">02.</div>
                <h3>Livrables</h3>
                <p>
                  Nous vous envoyons les visuels de votre futur site et notre
                  guide d'accompagnement à la rédaction de contenu.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-panel">
              <div className="process-content">
                <div className="step">03.</div>
                <h3>Développement</h3>
                <p>
                  Nous assurons le développement et la mise en ligne de votre
                  site ou de votre application web.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="process-panel">
              <div className="process-content">
                <div className="step">04.</div>
                <h3>Accompagnement</h3>
                <p>
                  Nous vous expliquons comment suivre les performances de votre
                  site pour prendre les bonnes décisions webmarketing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
