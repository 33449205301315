import React from "react";

const ECommerce = () => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <div className="head">
          Vous souhaitez vendre vos produits directement en ligne&nbsp;? Nous
          concevons un site e-commerce comprenant un système de paiement et de
          gestion des stocks.
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <p>
          La solution e-commerce que nous utilisons pour développer votre site
          vous permettra entre autre de : gérer vos stocks, vos remboursements,
          faire vos relances e-mail, voir vos statistiques sur smartphone en
          temps réel et bien plus encore !
        </p>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <p>
          Votre site e-commerce sera optimisé pour le référencement naturel
          (SEO) afin d'augmenter votre trafic et vos ventes. Nous pouvons
          également vous accompagner dans une stratégie webmarketing poussée.
        </p>
      </div>
    </div>
  );
};

export default ECommerce;
