import React from "react";

const Features = () => {
  return (
    <section id="service4" className="service service-1 service-4 bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="heading heading-2 mb-60">
                  <div className="subheading">
                    Des caractéristiques communes à tous nos sites
                  </div>
                  <h2>Les avantages</h2>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7">
                <p className="text-description">
                  Nous créons des sites rapides, sécurisés et optimisés pour
                  générer du trafic et des ventes grâce à des technologies
                  modernes : GatsbyJs, ReactJS, AWS, etc.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <i className="fal fa-palette" />
                  </div>
                  <div className="service-content">
                    <h3>Design sur mesure</h3>
                    <p>
                      Nous définissons le design et les fonctionnalités de votre
                      site en fonction de votre besoin.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <i className="fal fa-mobile-android" />
                  </div>
                  <div className="service-content">
                    <h3>Adapté à tous les écrans</h3>
                    <p>
                      Tous nos sites s'affichent parfaitement selon les
                      différentes tailles d'écran : ordinateur, tablette,
                      téléphone.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <i className="fal fa-pencil" />
                  </div>
                  <div className="service-content">
                    <h3>Facilement éditable</h3>
                    <p>
                      Vous pourrez modifier facilement tous les contenus (textes
                      et images) de votre site grâce à une interface simple
                      d'utilisation.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <i className="fal fa-fighter-jet" />
                  </div>
                  <div className="service-content">
                    <h3>Rapide et sécurisé</h3>
                    <p>
                      Nos sites sont très rapides et sécurisés grâce à la
                      technologie (JAMstack) que nous utilisons.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <i className="fal fa-users" />
                  </div>
                  <div className="service-content">
                    <h3>Optimisé pour générer du trafic</h3>
                    <p>
                      Notre code est propre et optimisé pour le référencement
                      naturel. Nos outils facilitent le déploiement d'une
                      stratégie webmarketing.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="service-panel">
                  <div className="service-icon">
                    <i className="fal fa-binoculars" />
                  </div>
                  <div className="service-content">
                    <h3>Suivi des performances</h3>
                    <p>
                      Vous pourrez voir le nombre de personnes qui visite votre
                      site grâce à la mise en place de Google Analytics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
