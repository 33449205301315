import React from "react";

import VitrineContent from "./bloc-services-vitrine";
import EcommerceContent from "./bloc-services-eCommerce";
import ApplicationContent from "./bloc-services-webApp";

class Services extends React.Component {
  state = {
    typeOffreNew: this.props.typeOffre ? this.props.typeOffre : "vitrine"
  };

  displayContent = typeOffre => {
    switch (typeOffre) {
      case "vitrine":
        return <VitrineContent />;
      case "ecommerce":
        return <EcommerceContent />;
      case "application":
        return <ApplicationContent />;
      default:
        return <VitrineContent />;
    }
  };

  handleClick = typeOffreNew => event => {
    this.setState({ typeOffreNew });
  };

  FirstChild = props => {
    const childrenArray = React.Children.toArray(props.children);
    return childrenArray[0] || null;
  };

  render() {
    const { typeOffreNew } = this.state;
    return (
      <section id="service3" className="service service-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <ul className="nav nav-tabs">
                <li className="service-panel">
                  <button
                    onClick={this.handleClick("vitrine")}
                    className={typeOffreNew === "vitrine" ? "active" : null}
                  >
                    <h3>Site vitrine</h3>
                  </button>
                </li>
                <li className="service-panel">
                  <button
                    onClick={this.handleClick("ecommerce")}
                    className={typeOffreNew === "ecommerce" ? "active" : null}
                  >
                    <h3>Site E-commerce</h3>
                  </button>
                </li>
                <li className="service-panel">
                  <button
                    onClick={this.handleClick("application")}
                    className={typeOffreNew === "application" ? "active" : null}
                  >
                    <h3>Application web</h3>
                  </button>
                </li>
              </ul>
              <div className="tab-content">
                {this.displayContent(typeOffreNew)}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
