import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";

import Services from "../components/offres/bloc-services";
import Features from "../components/offres/bloc-features";
import Process from "../components/offres/bloc-process";
import Prices from "../components/offres/bloc-prices";
// import Realisations from "../components/offres/bloc-realisations";

const Offres = ({ location, data }) => {
  return (
    <Layout>
      <SEO
        title="Création de site vitrine, e-commerce ou application web"
        description="Nous créons des sites, plus rapides, plus sécurisés et optimisés pour générer du trafic et des ventes grâce à une technologie moderne"
      />
      <Banner
        title="Nos offres"
        baseline="Nous concevons des sites web modernes et performants"
        imageBanner={data.imageBanner.childImageSharp.fluid}
      />
      <Services
        typeOffre={location.state ? location.state.typeOffre : "vitrine"}
      />
      <Features />
      <Process />
      <Prices />
      {/* <Realisations /> */}
    </Layout>
  );
};

export default Offres;

export const pageQuery = graphql`
  query {
    imageBanner: file(
      relativePath: { eq: "background/bannerOffresOriginal2.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
