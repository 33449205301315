import React from "react";

const Vitrine = () => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-4">
        <div className="head">
          Vous souhaitez faire connaître votre marque, vos produits ou vos
          services&nbsp;? Nous concevons un site vitrine adapté à vos besoins !
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <p>
          Nous vous proposons deux types de site vitrine : un site dont le
          contenu est situé sur une page ou un site dont le contenu est situé
          sur plusieurs pages.
        </p>
        <p>
          Nous recommandons le site sur une page pour les clients disposant d'un
          budget limité. L'avantage est qu'il permet d'avoir un site
          professionnel pour vous faire connaître et vendre vos produits et
          services.
        </p>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-4">
        <p>
          Nous recommandons le site multi-pages pour les clients souhaitant
          accélérer leur business sur internet. L'avantage est qu'il permet de
          mettre en place une stratégie de référencement optimale pour générer
          plus de trafic et de ventes.
        </p>
        <p>
          Nous vous accompagnons dans le développement de votre site web et dans
          la stratégie de référencement à adopter en fonction de votre budget.
        </p>
      </div>
    </div>
  );
};

export default Vitrine;
