import React from "react";
import Slider from "react-slick";
import Link from "gatsby-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SampleNextArrow = props => {
  const { onClick } = props;
  return (
    <i
      className="fal fa-long-arrow-alt-right fa-2x next-arrow"
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = props => {
  const { onClick } = props;
  return (
    <i
      className="fal fa-long-arrow-alt-left fa-2x prev-arrow"
      onClick={onClick}
    />
  );
};

const Prices = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <section id="pricing3" className="pricing pricing-1 pricing-3 bg-gray">
      <div className="container">
        <div className="heading heading-2">
          <div className="row mb-60">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="subheading">
                Choisissez la solution qui vous correspond
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <h2>Nos 4 offres</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <p>
                Nos tarifs dépendent de vos besoins en termes de fonctionnalités
                et d'accompagnement pour le référencement de votre site. Nous
                vous proposerons toujours une solution adaptée à votre besoin et
                à votre budget !
              </p>
            </div>
          </div>
        </div>
        <Slider {...settings}>
          <div className="price-table">
            <div className="panel">
              <div className="panel-body">
                <p className="plan">Site vitrine - Une page</p>
              </div>
              <ul className="list-group">
                <li className="list-group-item">1 page</li>
                <li className="list-group-item">Adapté sur tous les écrans</li>
                <li className="list-group-item">Hébergement du site</li>
                <li className="list-group-item">
                  Interface d'édition de vos contenus
                </li>
                <li className="list-group-item">
                  Optimisé pour le référencement (SEO)
                </li>
                <li className="list-group-item">
                  Mise en place de Google Analytics
                </li>
                <li className="list-group-item">
                  Guide de rédaction de contenu
                </li>
                <li className="list-group-item">
                  Guide de suivi des performances
                </li>
              </ul>
              <div className="panel-footer">
                <Link
                  className="btn btn-ternary btn-block btn-hover"
                  to="/contact"
                >
                  Contactez-nous
                </Link>
              </div>
            </div>
          </div>
          <div className="price-table">
            <div className="panel">
              <div className="panel-body">
                <p className="plan">Site vitrine - Multi-pages</p>
              </div>
              <ul className="list-group">
                <li className="list-group-item">Jusqu'à 100 pages</li>
                <li className="list-group-item">Adapté sur tous les écrans</li>
                <li className="list-group-item">Hébergement du site</li>
                <li className="list-group-item">
                  Interface d'édition de vos contenus
                </li>
                <li className="list-group-item">
                  Optimisé pour le référencement (SEO)
                </li>
                <li className="list-group-item">
                  Mise en place de Google Analytics
                </li>
                <li className="list-group-item">
                  Guide de rédaction de contenu
                </li>
                <li className="list-group-item">
                  Guide de suivi des performances
                </li>
              </ul>
              <div className="panel-footer">
                <Link
                  className="btn btn-ternary btn-block btn-hover"
                  to="/contact"
                >
                  Contactez-nous
                </Link>
              </div>
            </div>
          </div>
          <div className="price-table">
            <div className="panel">
              <div className="panel-body">
                <p className="plan">Site E-commerce</p>
              </div>
              <ul className="list-group">
                <li className="list-group-item">Boutique en ligne</li>
                <li className="list-group-item">Système de paiement</li>
                <li className="list-group-item">Gestion des stocks</li>
                <li className="list-group-item">Adapté sur tous les écrans</li>
                <li className="list-group-item">Hébergement du site</li>
                <li className="list-group-item">
                  Interface d'édition de vos contenus
                </li>
                <li className="list-group-item">
                  Optimisé pour le référencement (SEO)
                </li>
                <li className="list-group-item">
                  Mise en place de Google Analytics
                </li>
                <li className="list-group-item">
                  Guide de rédaction de contenu
                </li>
                <li className="list-group-item">
                  Guide de suivi des performances
                </li>
              </ul>
              <div className="panel-footer">
                <Link
                  className="btn btn-ternary btn-block btn-hover"
                  to="/contact"
                >
                  Contactez-nous
                </Link>
              </div>
            </div>
          </div>
          <div className="price-table">
            <div className="panel">
              <div className="panel-body">
                <p className="plan">Application Web</p>
              </div>
              <ul className="list-group">
                <li className="list-group-item">Parlons-en ensemble !</li>
              </ul>
              <div className="panel-footer">
                <Link
                  className="btn btn-ternary btn-block btn-hover"
                  to="/contact"
                >
                  Contactez-nous
                </Link>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Prices;
